import { useQuery } from 'react-apollo';

import { usePageSpaceKey } from '@confluence/page-context';

import { SpaceEnabledContentTypesQuery } from '../queries/SpaceEnabledContentTypesQuery.graphql';
import type {
	SpaceEnabledContentTypesQuery as SpaceEnabledContentTypesQueryType,
	SpaceEnabledContentTypesQueryVariables,
} from '../queries/__types__/SpaceEnabledContentTypesQuery';
import { GetDefaultSpaceQuery } from '../queries/GetDefaultSpaceQuery.experimentalgraphql';
import type {
	GetDefaultSpaceQuery as GetDefaultSpaceQueryType,
	GetDefaultSpaceQueryVariables,
} from '../queries/__types__/GetDefaultSpaceQuery';

// NOTE: This is a copy of the useSpaceEnabledContentTypes hook from create-blank-fabric-page
// We had to duplicate it here because the original hook could not be imported without creating
// a circular dependency between create-blank-fabric-page and onboarding-live-doc-changeboarding
//
// Do NOT make changes to or re-use this hook, it should be cleaned up once the changeboarding
// feature is deprecated. If you need to re-use this, please look at useSpaceEnabledContentTypes instead.

export const useIsLivePagesAvailableInSpace = () => {
	const [spaceKey] = usePageSpaceKey();

	const { data: defaultSpaceKeyData } = useQuery<
		GetDefaultSpaceQueryType,
		GetDefaultSpaceQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		GetDefaultSpaceQuery,
		{
			skip: spaceKey !== undefined,
		},
	);

	const { data: enabledContentTypesData } = useQuery<
		SpaceEnabledContentTypesQueryType,
		SpaceEnabledContentTypesQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceEnabledContentTypesQuery,
		{
			errorPolicy: 'all',
			skip: !spaceKey && !defaultSpaceKeyData,
			variables: {
				spaceKey: spaceKey || defaultSpaceKeyData?.experimentalPromotedSpaces?.nodes?.[0]?.id,
			},
		},
	);

	return enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isLivePagesEnabled
		: false;
};
