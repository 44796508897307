export const LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS: Record<
	string,
	`onboarding-live-doc-changeboarding:${string}`
> = {
	hasDismissedIntroSpotlight: 'onboarding-live-doc-changeboarding:hasDismissedIntroSpotlight',
	hasDismissedLiveDocTour: 'onboarding-live-doc-changeboarding:hasDismissedLiveDocTour',
	hasDismissedEditorControlsTour:
		'onboarding-live-doc-changeboarding:hasDismissedEditorControlsTour',
	hasDismissedContentWrapperTour:
		'onboarding-live-doc-changeboarding:hasDismissedContentWrapperTour',
	hasDismissedAiButtonTour: 'onboarding-live-doc-changeboarding:hasDismissedAiButtonTour',
} as const;
