import { useSessionData } from '@confluence/session-data';

// Production trait: https://data-portal.internal.atlassian.com/targeting/traits/f0c5d949-91be-46e0-b440-7d7a953c9159
const IS_ESTABLISHED_USER_TRAIT_NAME = 'confluence_is_established_user';

// Staging trait: https://data-portal-stg.internal.atlassian.com/targeting/traits/4015e85e-221b-4d95-a945-c68548e21a8b
// NOTE: DO NOT USE Staging trait named as `confluence_is_established_user` - this is a legacy trait created with wrong evaluation type and won't work for staging
const IS_ESTABLISHED_USER_TRAIT_NAME_STAGING = 'confluence_is_established_user_stg';

export const useIsEstablishedUserTraitName = () => {
	const { environment } = useSessionData();

	if (environment !== 'PRODUCTION') {
		return IS_ESTABLISHED_USER_TRAIT_NAME_STAGING;
	}

	return IS_ESTABLISHED_USER_TRAIT_NAME;
};
