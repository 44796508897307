import { isFedRamp } from '@atlassian/atl-context';

export const isContentTypeEnabledInCurrentEnv = (
	contentType: 'whiteboard' | 'database' | null | undefined,
): boolean => {
	if (contentType === 'database' || contentType === 'whiteboard') {
		return !isFedRamp();
	}

	return true;
};
