/**
 * These are loadable names that cross reference by multiple places.
 * They were kept as constants here instead of automatically generated for consistency.
 * ONLY put names here if they are going to be used in _mustPreloadWhenRenderingSPA.
 */
export enum MustPreloadLoadableNames {
	ClassicRendererLoader = 'ClassicRendererLoader',
	ClassicEditorContextProviderLoader = 'ClassicEditorContextProviderLoader',
	NativeCollabLoader = 'NativeCollabLoader',
	FullPageEditorLoader = 'FullPageEditorLoader',
	ViewPageRouteComponentLoader = 'ViewPageRouteComponentLoader',
	LivePageComponentLoader = 'LivePageComponentLoader',
	EditorByLineArea = 'EditorByLineArea',
}
