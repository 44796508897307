import type { FC } from 'react';
import React, { useEffect, useMemo, useRef } from 'react';

import type { InlineCommentCreateComponentProps } from '@atlaskit/editor-plugins/annotation';

import {
	ADD_INLINE_COMMENT_EXPERIENCE,
	ADD_INLINE_COMMENT_LOAD_EXPERIENCE,
	ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
	startEditorExperiences,
} from '@confluence/experience-tracker';
import { findMatches, indexTextNodes, IGNORE_SELECTORS } from '@confluence/highlight-actions';
import {
	InlineCommentMode,
	InlineCommentFramework,
} from '@confluence/inline-comments-common/entry-points/enum';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { CommentCreationLocation } from '@confluence/inline-comments-queries';

import { CreateComment } from '../CreateComment';
import type { SelectionOptions } from '../renderer/SelectionComponent';

export type CreateComponentProps = {
	pageId: string;
	closeObjectSidebar?: () => void;
};

export const CreateComponent: FC<InlineCommentCreateComponentProps & CreateComponentProps> = ({
	pageId,
	onCreate,
	onClose,
	dom: selectionElement,
	textSelection,
	inlineNodeTypes,
	isOpeningMediaCommentFromToolbar = false,
	closeObjectSidebar,
}) => {
	const editorNode = useRef<Element | null>(null);
	const indexFound = useRef(0);
	const textMatches = useRef(0);

	const isLivePage = useIsCurrentPageLive();

	useEffect(() => {
		closeObjectSidebar && closeObjectSidebar();
		startEditorExperiences(
			{
				name: ADD_INLINE_COMMENT_EXPERIENCE,
				attributes: {
					mode: isLivePage ? InlineCommentMode.LIVE : InlineCommentMode.EDIT,
					framework: InlineCommentFramework.ANNOTATION_PROVIDER,
				},
			},
			{
				name: ADD_INLINE_COMMENT_LOAD_EXPERIENCE,
			},
			{
				name: ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
			},
		);

		// both editor and inline comment sidebar share the same class, target the element that isn't the sidebar
		const editorNodes = Array.from(
			document.querySelectorAll('.ak-editor-content-area > .ProseMirror'),
		);
		if (editorNodes.length) {
			const foundEditorNode = editorNodes.find(
				(element) => !element.closest('#editor-create-sidebar'),
			);
			if (foundEditorNode) {
				editorNode.current = foundEditorNode;
			}
		}

		if (selectionElement && editorNode.current && textSelection) {
			const ignoredNodes: HTMLElement[] = Array.from(
				editorNode.current.querySelectorAll(IGNORE_SELECTORS),
			);

			const { text, nodesStartPos, nodes } = indexTextNodes(editorNode.current, ignoredNodes);

			const { index, numMatches } = findMatches(
				text,
				textSelection,
				// we prefer the TextNode rather than <p> or headings
				selectionElement.parentNode ? selectionElement.parentNode : selectionElement,
				// default to 0 since the Editor wraps the selection in a <span> now
				0,
				nodesStartPos,
				nodes,
			);

			indexFound.current = index;
			textMatches.current = numMatches;
		}
	}, [selectionElement, textSelection, isLivePage, closeObjectSidebar]);

	const selectionOptions: SelectionOptions = useMemo(
		() => ({
			originalSelection: textSelection!,
			numMatches: textMatches.current,
			matchIndex: indexFound.current,
			inlineNodeTypes,
			createdFrom: isLivePage ? CommentCreationLocation.LIVE : CommentCreationLocation.EDITOR,
		}),
		[textSelection, textMatches, indexFound, isLivePage, inlineNodeTypes],
	);

	return (
		<CreateComment
			pageId={pageId}
			annotationElement={selectionElement}
			selectionOptions={selectionOptions}
			onClose={onClose}
			onCreate={onCreate}
			isEditor
			isOpeningMediaCommentFromToolbar={isOpeningMediaCommentFromToolbar}
		/>
	);
};
