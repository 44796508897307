import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { useSessionData } from '@confluence/session-data';

const Nav4ChangeboardingQuery = gql`
	query useIsNav4ChangeboardingQuery($cloudId: String!, $userId: String!) @experimental {
		experimentalSiteUserPersonalization(cloudId: $cloudId, userId: $userId) {
			attributes {
				name
				value
			}
		}
	}
`;

const NAV_4_CHANGEBOARDING_ENROLLED_TRAIT =
	'segment_confluence_nav_4_changeboarding_cloned_siteuser';
const NAV_4_CHANGEBOARDING_COMPLETED_TRAIT = 'completed_cc-nav-4-changeboarding-prod';

export const useIsNav4Changeboarding = () => {
	const { cloudId, userId } = useSessionData();

	const isNav4Enabled = useIsNav4Enabled();

	const { data, loading, error } = useQuery(Nav4ChangeboardingQuery, {
		variables: { cloudId, userId },
		skip: !userId || !isNav4Enabled,
	});

	if (!isNav4Enabled) {
		return {
			isUserEnrolledInNav4Changeboarding: false,
			isUserCompletedNav4Changeboarding: false,
		};
	}

	const attributes = data?.experimentalSiteUserPersonalization?.attributes;

	const isUserEnrolledInNav4Changeboarding = Boolean(
		attributes?.some(
			({ name, value }: { name: string; value: string }) =>
				name === NAV_4_CHANGEBOARDING_ENROLLED_TRAIT && value === 'true',
		),
	);

	const isUserCompletedNav4Changeboarding = Boolean(
		attributes?.some(
			({ name, value }: { name: string; value: string }) =>
				name === NAV_4_CHANGEBOARDING_COMPLETED_TRAIT && !!value,
		),
	);

	return {
		isUserEnrolledInNav4Changeboarding,
		isUserCompletedNav4Changeboarding,
		loading,
		error,
	};
};
