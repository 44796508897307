import { LoadableBackground } from '@confluence/loadable';

export { getShortcutString, getShortcutStrings, isMac } from './get-shortcut-string';
export {
	ALLOW_SHORTCUTS_CLASS,
	ALLOW_SHORTCUTS_CLASS_IN_MODAL,
	RESERVED_SHORTCUTS,
} from './constants';
export {
	EDITOR2_SHORTCUTS,
	BROWSER_SAVE_SHORTCUT,
	COMMENT_EDITOR_SHORTCUT,
	INLINE_COMMENTS_SHORTCUT,
	BLOG_SHORTCUT,
	GLOBAL_CREATE_SHORTCUT,
	STAR_BUTTON_SHORTCUT,
	CREATE_COMMENT_SHORTCUT,
	LINK_TO_THIS_PAGE_SHORTCUT,
	EDIT_SHORTCUT,
	PRESENTATION_MODE_SHORTCUT,
	WATCH_CONTENT_SHORTCUT,
	SHARE_PAGE_SHORTCUT,
	LABELS_SHORTCUT,
	VIEW_ATTACHMENTS_SHORTCUT,
	NEXT_COMMENT_SHORTCUT,
	PREV_COMMENT_SHORTCUT,
	NEXT_UNREAD_COMMENT_SHORTCUT,
	SUBMIT_FEEDBACK_SHORTCUT,
	NAVIGATION_SHORTCUT,
	GO_HOME_SHORTCUT,
	NOTIFICATIONS_SHORTCUT,
	GO_TO_RECENT_SHORTCUT,
	GO_TO_STARRED_SHORTCUT,
	SPACE_OVERVIEW_SHORTCUT_NAV_3,
	SPACE_OVERVIEW_SHORTCUT,
	NAVIGATION_SLASH_SHORTCUT,
	SIDE_NAVIGATION_SHORTCUT,
	UNDO_SHORTCUT,
	PREV_TEMPLATE_SHORTCUT,
	NEXT_TEMPLATE_SHORTCUT,
	HELP_SHORTCUT,
	AUTOMATION_SHORTCUT,
	AI_MATE_SHORTCUT,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR,
	CREATE_INLINE_COMMENT_SHORTCUT_MAC,
	CREATE_INLINE_COMMENT_SHORTCUT_WIN,
	GLOBAL_CREATE_SHORTCUT_IN_EDIT_MAC,
	GLOBAL_CREATE_SHORTCUT_IN_EDIT_WIN,
	HELP_SHORTCUT_IN_EDIT,
	SIDE_NAVIGATION_SHORTCUT_IN_EDIT,
	STAR_BUTTON_SHORTCUT_IN_EDIT,
	LIVE_PAGE_MODE_SWITCH_TO_EDIT,
	LIVE_PAGE_MODE_SWITCH_TO_VIEW,
	UNIVERSAL_NEXT_COMMENT_SHORTCUT,
	UNIVERSAL_PREV_COMMENT_SHORTCUT,
} from './confluence-shortcuts';

export { ShortcutListener, ShortcutType } from './ShortcutListener';
export type { ShortcutListenerProps, ShortcutEvent } from './ShortcutListener';
export { ShortcutVisualizer } from './ShortcutVisualizer';
export { AppNavigationShortcut } from './AppNavigationShortcut';
export { useKeyboardShortcutsState } from './useKeyboardShortcutsState';

export const GeneralShortcutListener = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-GeneralShortcutListener" */ './GeneralShortcutListener'
			)
		).GeneralShortcutListener,
});
