import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { useSessionData } from '@confluence/session-data';
import { useIsEstablishedUserTraitName } from '@confluence/onboarding-helpers/entry-points/hooks/useIsEstablishedUserTraitName';
const UseIsEstablishedUserUserPersonalizationQuery = gql`
	query useIsEstablishedUserUserPersonalizationQuery($userId: String!) @experimental {
		experimentalUserPersonalization(userId: $userId) {
			attributes {
				name
				value
			}
		}
	}
`;

export const useIsEstablishedUser = ({ skip }: { skip: boolean }) => {
	const { userId } = useSessionData();
	const { data, loading, error } = useQuery(UseIsEstablishedUserUserPersonalizationQuery, {
		skip: skip || !userId,
		variables: { userId },
	});
	const isEstablishedUserTraitName = useIsEstablishedUserTraitName();

	const isEstablishedUser = Boolean(
		data?.experimentalUserPersonalization?.attributes?.some(
			({ name, value }: { name: string; value: string }) =>
				name === isEstablishedUserTraitName && value === 'true',
		),
	);
	return { isEstablishedUser, loading, error };
};
